import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CouponsAPI from "../../../api/firebase/CouponsAPI";
import CustomButton from "../../common/Button/CustomButton";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
const OrderSummary = ({
  items,
  max_width = "sm",
  no_wrap = true,
  order = null,
}) => {
  const couponsAPI = new CouponsAPI();
  const [allCoupons, setAllCoupons] = useState([]);
  //console.log("order summary:", order);
  const [shippingCost, setShippingCost] = useState(0);
  const [couponCodeByUser, setCouponCodeByUser] = useState("");
  const [couponErrorMsg, setCouponErrorMsg] = useState("");
  const [couponApplied, setCouponApplied] = useState(null);
  const [cartAmount, setCartAmount] = useState(0);
  const [isCOD, setIsCOD] = useState(false);
  const [codCharges, setCODCharges] = useState(0);
  const userProfileContext = useContext(UserProfileContext);
  // console.log("allCoupons:", allCoupons);
  //console.log("userProfileContext:", userProfileContext.state);
  useEffect(() => {
    // check final amount
    const fAmount = items.reduce(
      (a, v) => (a = a + v.listPrice * v.quantity),
      0
    );
    setCartAmount(fAmount);
    userProfileContext.setState({
      ...userProfileContext.state,
      codCharges: codCharges,
    });

    if (order !== null) {
      console.log("order:", order);
      setCouponApplied(order.couponApplied ? order.couponApplied : null);
      setShippingCost(order.shippingCost ? order.shippingCost : 0);
      if (order.payment.method === "cod") {
        setIsCOD(true);
        setCODCharges(order.codCharges ? order.codCharges : 0);
      }
    } else {
      if (fAmount > process.env.REACT_APP_FREE_SHIPPING_ABOVE) {
        setShippingCost(0);
        // userProfileContext.setState({
        //   ...userProfileContext.state,
        //   shippingCost: 0,
        // });
      } else {
        setShippingCost(60);
        // userProfileContext.setState({
        //   ...userProfileContext.state,
        //   shippingCost: 60,
        // });
      }
    }
    // console.log("fAmount:", fAmount);

    couponsAPI
      .getCoupons()
      .then((res) => {
        // console.log("Res:", res);
        if (res.data.length > 0) {
          setAllCoupons([...res.data]);
        } else {
          setAllCoupons([]);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
    console.log("userProfileContext.state:", userProfileContext.state);
    if (
      order === null &&
      userProfileContext.state.couponApplied !== undefined &&
      userProfileContext.state.couponApplied !== null
    ) {
      setCouponApplied({ ...userProfileContext.state.couponApplied });
      setCouponCodeByUser(userProfileContext.state.couponApplied.couponCode);
    }
    if (
      order !== null &&
      order.couponApplied !== undefined &&
      order.couponApplied !== null
    ) {
      setCouponApplied({ ...order.couponApplied });
      setCouponCodeByUser(order.couponApplied.couponCode);
    }
  }, []);
  useEffect(() => {
    if (allCoupons.length > 0 && order === null) {
      // get all active and available coupons
      const activeCoupons = allCoupons.filter(
        (coupon) =>
          coupon.isActive === true && coupon.users.length < coupon.maxUses
      );
      // get coupon which have closest minShopping value to cartAmount from activeCoupons
      const _couponToApply = activeCoupons
        .filter((obj) => obj.minShopping <= cartAmount) // Step 1: Filter out larger amounts
        .reduce(
          (prev, curr) => {
            // Step 2: Find the closest amount
            return cartAmount - curr.minShopping < cartAmount - prev.minShopping
              ? curr
              : prev;
          },
          { minShopping: 0 }
        ); // Initialize with an infinitely small amount
      //console.log("couponToApply:", _couponToApply);
      // setCouponToApply({..._couponToApply});
      if (_couponToApply.minShopping > 0) {
        userProfileContext.setState({
          ...userProfileContext.state,
          couponApplied: { ..._couponToApply },
        });
        setCouponApplied({ ..._couponToApply });
        setCouponCodeByUser(_couponToApply.couponCode);
      } else {
        userProfileContext.setState({
          ...userProfileContext.state,
          couponApplied: null,
        });
        setCouponApplied(null);
        setCouponCodeByUser("");
      }
    }
  }, [allCoupons]);
  useEffect(() => {
    userProfileContext.setState({
      ...userProfileContext.state,
      codCharges: codCharges,
    });
  }, [codCharges]);

  useEffect(() => {
    userProfileContext.setState({
      ...userProfileContext.state,
      shippingCost: shippingCost,
    });
  }, [shippingCost]);

  const applyCoupon = () => {
    const filteredCoupon = allCoupons.filter(
      (coupon) => coupon.id === couponCodeByUser && coupon.isActive === true
    );

    // console.log("filteredCoupon:", filteredCoupon);
    if (filteredCoupon.length === 1) {
      const userCoupon = filteredCoupon[0];
      const isValid = cartAmount >= userCoupon.minShopping;
      if (!isValid) {
        //console.log("Coupon cant be applied");
        setCouponApplied(null);
        setCouponErrorMsg(
          `Minimum amount to use this coupon is \u20B9${userCoupon.minShopping}`
        );
        userProfileContext.setState({
          ...userProfileContext.state,
          couponApplied: null,
        });
      } else {
        const isExpired = userCoupon.maxUses === userCoupon.users.length;
        if (isExpired) {
          //console.log("Coupon Expired");
          setCouponApplied(null);
          setCouponErrorMsg("Coupon Expired!");
          userProfileContext.setState({
            ...userProfileContext.state,
            couponApplied: null,
          });
        } else {
          //console.log("Coupon Valid");
          setCouponErrorMsg("");
          setCouponApplied({ ...userCoupon });
          userProfileContext.setState({
            ...userProfileContext.state,
            couponApplied: { ...userCoupon },
          });
        }
      }
    } else {
      //console.log("Invalid Coupon Code!");
      setCouponErrorMsg("Invalid Coupon Code!");
      setCouponApplied(null);
      userProfileContext.setState({
        ...userProfileContext.state,
        couponApplied: null,
      });
    }
  };
  //console.log("coupon applied:", couponApplied);
  return (
    <Container
      sx={{ padding: 0, overflowX: "hidden" }}
      component="main"
      maxWidth={max_width}
    >
      <CssBaseline />
      <Box
        sx={{
          padding: 0,
          marginTop: "1rem",
          marginLeft: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container spacing={1} sx={{ padding: 0, paddingRight: "1rem" }}>
          {items.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Grid item xs={1}>
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ fontWeight: "400" }}
                  >
                    {index + 1}.
                  </Typography>
                </Grid>
                <Grid item xs={9} textAlign="left">
                  <Typography
                    variant="body2"
                    component="p"
                    sx={{ fontWeight: "400" }}
                    noWrap={no_wrap}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="caption" component="p">
                    Price: &#8377; {item.listPrice} x {item.quantity} no's
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  {/* <Typography
                    variant="body2"
                    sx={{fontWeight: "400", marginRight: "5px"}}
                  >
                    Total
                  </Typography> */}
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    &#8377;{item.quantity * item.listPrice}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={8} textAlign="left" justifyContent="center">
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "500" }}
              noWrap={no_wrap}
            >
              Cart amount
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" justifyContent="center">
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontWeight: 500,
              }}
            >
              {"\u20B9" + cartAmount}
            </Typography>
          </Grid>
          <Grid item xs={8} textAlign="left" justifyContent="center">
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "500" }}
              noWrap={no_wrap}
            >
              Shipping cost
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign="right" justifyContent="center">
            <Typography
              variant="body1"
              component="p"
              sx={{
                fontWeight: 500,
                color: shippingCost === 0 ? "darkgreen" : "darkred",
              }}
            >
              {shippingCost === 0
                ? `FREE`
                : "\u20B9" + `${shippingCost ? shippingCost : "0"}`}
            </Typography>
          </Grid>
          {isCOD && (
            <>
              <Grid item xs={8} textAlign="left" justifyContent="center">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontWeight: "500" }}
                  noWrap={no_wrap}
                >
                  COD Charges
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" justifyContent="center">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontWeight: 500,
                    color: codCharges === 0 ? "darkgreen" : "darkred",
                  }}
                >
                  {codCharges === 0
                    ? `FREE`
                    : "\u20B9" + `${codCharges ? codCharges : "0"}`}
                </Typography>
              </Grid>
            </>
          )}

          {shippingCost !== 0 && order === null && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  fontSize: "0.8rem",
                  color: "gray",
                  fontWeight: 500,
                }}
              >
                {process.env.REACT_APP_FREE_SHIPPING_ABOVE - cartAmount < 99
                  ? `(Add another item for free shipping)`
                  : ``}
                {process.env.REACT_APP_FREE_SHIPPING_ABOVE - cartAmount >= 99
                  ? `(Add Items worth ₹${
                      process.env.REACT_APP_FREE_SHIPPING_ABOVE - cartAmount
                    } for free shipping)`
                  : ``}
              </Typography>
            </Grid>
          )}
          {couponApplied !== null && (
            <>
              <Grid item xs={8} textAlign="left" justifyContent="center">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontWeight: "500" }}
                  noWrap={no_wrap}
                >
                  Coupon Discount{" "}
                  {order !== null ? `(${couponCodeByUser})` : ""}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" justifyContent="center">
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ color: "darkgreen", fontWeight: 500 }}
                >
                  - &#8377;{couponApplied.amount}
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent="center">
                {order === null && (
                  <Button
                    sx={{ fontSize: "smaller" }}
                    variant="text"
                    size="small"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setCouponApplied(null);
                      userProfileContext.setState({
                        ...userProfileContext.state,
                        couponApplied: null,
                      });
                    }}
                  >
                    Remove coupon
                  </Button>
                )}
              </Grid>
            </>
          )}
          {order === null && (
            <Grid item xs={12}>
              <Divider />
              <Accordion sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    color: "darkgreen",
                    fontWeight: 500,
                  }}
                >
                  have any coupon code ?
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <Grid container sx={{ m: 0 }} justifyContent="space-around">
                    <Grid item xs={6}>
                      {/* textbox for coupon code */}
                      <TextField
                        label="Coupon Code"
                        variant="standard"
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setCouponCodeByUser(
                            e.target.value.trim().toUpperCase()
                          );
                        }}
                        value={couponCodeByUser}
                        error={couponErrorMsg !== ""}
                        helperText={
                          couponApplied === null
                            ? couponErrorMsg
                            : "Coupon Applied!"
                        }
                        color={couponApplied !== null ? "success" : ""}
                      />
                    </Grid>
                    {order === null && (
                      <Grid item xs={4}>
                        <CustomButton
                          onClick={applyCoupon}
                          disabled={!couponCodeByUser}
                          variant="contained"
                          sx={{ ml: "5px", mb: "-20px" }}
                          color="warning"
                        >
                          Apply
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {order === null && (
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isCOD ? "cod" : "online"}
                  onChange={(e) => {
                    setIsCOD(e.target.value === "cod");
                    setCODCharges(
                      e.target.value === "cod"
                        ? Number(process.env.REACT_APP_COD_CHARGES) -
                            shippingCost
                        : 0
                    );
                  }}
                >
                  <FormControlLabel
                    value="online"
                    control={<Radio />}
                    label="Pay now"
                  />
                  <FormControlLabel
                    value="cod"
                    control={<Radio />}
                    label={`Pay on delivery (Charges:+ ₹${
                      Number(process.env.REACT_APP_COD_CHARGES) - shippingCost
                    })`}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={5}>
            <Typography variant="body1" sx={{ fontWeight: "500 !important" }}>
              Total
            </Typography>
          </Grid>
          <Grid item xs={7} textAlign="right">
            <Typography variant="body1" sx={{ fontWeight: "500 !important" }}>
              Final amount
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{ pt: "0 !important" }}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              ({items.reduce((a, v) => a + v.quantity, 0)} items)
            </Typography>
          </Grid>
          <Grid item xs={7} sx={{ pt: "0 !important" }} textAlign="right">
            {!couponApplied && (
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                &#8377;
                {shippingCost + cartAmount + codCharges}
              </Typography>
            )}
            {couponApplied && (
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                &#8377;
                {shippingCost + cartAmount + codCharges - couponApplied.amount}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default OrderSummary;
